import React from 'react';
import ContentLoader, { IContentLoaderProps } from 'react-content-loader';
import {
    animationSpeed,
    skeletonBackgroundColor,
    skeletonForegroundColor,
    textBlockBorderRadius,
} from '~/src/components/skeletons/caption-block-skeleton/caption-block-skeleton.constants';
import * as Styled from './description-block-loader.styles';

export const DescriptionBlockLoader = (props: IContentLoaderProps) => (
    <ContentLoader
        speed={animationSpeed}
        width={'100%'}
        height={'100%'}
        backgroundColor={skeletonBackgroundColor}
        foregroundColor={skeletonForegroundColor}
        {...props}
    >
        <Styled.TitleRect x="0" y="0" rx={textBlockBorderRadius} ry={textBlockBorderRadius} />
        <Styled.TextBlockRect x="0" y="60" rx={textBlockBorderRadius} ry={textBlockBorderRadius} />
        <Styled.ButtonRect xPos={0} yPos={155} rx={textBlockBorderRadius} ry={textBlockBorderRadius} />
        <Styled.ButtonRect xPos={180} yPos={155} rx={textBlockBorderRadius} ry={textBlockBorderRadius} isRightBtn />
    </ContentLoader>
);
