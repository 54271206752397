import React, { FC, ReactElement, SyntheticEvent, useEffect, useMemo, useState } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';
import { openWindow } from '~/src/helpers/open-window.helper';
import {
    selectCurrentOrganizationConnectionStatus,
    selectIsConnectedCurrentOrganizationForButton,
    selectOrganizations,
    selectUserPartyId,
    selectOrganizationsLoading,
    selectUserProfile,
    selectWorkingPartyId,
} from '~/src/redux/user/user.selectors';
import { useMatchMedia } from '~/src/hooks/use-match-media.hook';
import { SER_CON_AGENTS_URL } from '~/src/params';
import {
    buttonText,
    littleTitle,
} from '~/src/components/blocks/main/caption-block-content/caption-block-content.constants';
import { getConnectedStatusAll } from '~/src/components/blocks/main/caption-block-content/caption-block-content.utils';
import {
    sendButtonAnalytics,
    sendDownloadLinkAnalytics,
} from '~/src/components/blocks/main/caption-block-content/caption-block-content.analytics';
import { PersonalStatusBlock } from './caption-block-content-components/personal-status-block/personal-status-block';
import { DefaultStatusContent } from '~/src/components/blocks/main/caption-block-content/caption-block-content-components/default-status-content/default-status-content';
import { ImageStatusBlock } from '~/src/components/blocks/main/caption-block-content/caption-block-content-components/image-status-block/image-status-block';
import { LegalStatusBlock } from '~/src/components/blocks/main/caption-block-content/caption-block-content-components/legal-status-block/legal-status-block';
import { ButtonsStatusBlock } from '~/src/components/blocks/main/caption-block-content/caption-block-content-components/buttons-status-block/buttons-status-block';
import { CaptionBlockSkeleton } from '~/src/components/skeletons/caption-block-skeleton/caption-block-skeleton';
import { MediaWidthEnum } from '~/src/models/enums/media-width.enum';
import { CounterpartyConnectStatusEnum } from '~/src/models/enums/counterparty-connect-status.enum';
import { SkeletonLoaderTypeEnum } from '~/src/components/skeletons/caption-block-skeleton/caption-block-skeleton.types';
import * as Styled from './caption-block-content.styles';

interface CaptionBlockProps {
    offerUrl: string;
    isAuth: boolean;
    isAuthFinished: boolean;
}

export const CaptionBlockContent: FC<CaptionBlockProps> = ({ offerUrl, isAuth, isAuthFinished }) => {
    const [isMobile] = useMatchMedia(MediaWidthEnum.mobile);
    const userProfile = useSelector(selectUserProfile);
    const isConnectedCurrentOrganizationForButton = useSelector(selectIsConnectedCurrentOrganizationForButton);
    const organizationConnectionStatus = useSelector(selectCurrentOrganizationConnectionStatus);
    const organizations = useSelector(selectOrganizations);
    const organizationsIsLoading = useSelector(selectOrganizationsLoading);
    const workingPartyId = useSelector(selectWorkingPartyId);
    const userPartyId = useSelector(selectUserPartyId);
    const [isLoading, setIsLoading] = useState(true);
    const [contentIsLoading, setContentIsLoading] = useState(true);
    const router = useRouter();

    const isConnectedStatus = organizationConnectionStatus === CounterpartyConnectStatusEnum.CONNECTED;

    useEffect(() => {
        const contentIsLoading = isAuth && isAuthFinished && !organizationsIsLoading && workingPartyId;
        const userIsNotAuth = !userProfile.id && isAuthFinished;

        if (contentIsLoading || userIsNotAuth) {
            setContentIsLoading(false);
        }
    }, [isAuth, isAuthFinished, workingPartyId, organizationsIsLoading, userProfile.id]);


    const buttonData = useMemo(() => {
        if (isConnectedCurrentOrganizationForButton === null) {
            return null;
        }

        setIsLoading(false);

        return { text: buttonText, url: SER_CON_AGENTS_URL };
    }, [isConnectedCurrentOrganizationForButton, workingPartyId]);

    const onClickButton = async (data: ReturnType<() => typeof buttonData>) => {
        if (!data) {
            return;
        }

        sendButtonAnalytics(data.text);
        await router.push({ pathname: `${data.url}`, query: router.query });
    };

    const onClickDownloadLink = (event: SyntheticEvent<HTMLElement>) => {
        event.preventDefault();
        sendDownloadLinkAnalytics();
        offerUrl && openWindow(offerUrl);
    };

    const getContentText = (getText: (str: string, isMobile?: boolean) => string | ReactElement) => {
        const currentOrganization = organizations.find((org) => org.partyId === workingPartyId);
        const fullOrganizationName = `${currentOrganization?.legalForm || ''} ${currentOrganization?.fullName}`;

        return getText(fullOrganizationName, isMobile);
    };

    const renderButtons = (hasLegal: boolean) => {
        return (
            <ButtonsStatusBlock
                buttonData={buttonData}
                organizationConnectionStatus={organizationConnectionStatus}
                isConnectedCurrentOrganizationForButton={isConnectedCurrentOrganizationForButton}
                isLoading={isLoading}
                onClickDownloadLink={onClickDownloadLink}
                onClickButton={onClickButton}
                hasLegal={hasLegal}
            />
        );
    };

    const renderContent = () => {
        if (contentIsLoading) {
            return(
                <>
                    <CaptionBlockSkeleton type={SkeletonLoaderTypeEnum.TITLE} />
                    <Styled.AuthContentWrapper isConnectedStatus={isConnectedStatus} withoutGradient>
                        <CaptionBlockSkeleton type={SkeletonLoaderTypeEnum.TEXT} />
                    </Styled.AuthContentWrapper>
                </>
            );
        }

        if (isAuth && isAuthFinished && organizations.length && workingPartyId && userPartyId) {
            return (
                <>
                    {workingPartyId !== userPartyId ? (
                        <LegalStatusBlock
                            organizationConnectionStatus={organizationConnectionStatus}
                            getContentText={getContentText}
                            isConnectedStatus={isConnectedStatus}
                            componentButtons={renderButtons(true)}
                        />
                    ) : (
                        <PersonalStatusBlock
                            organizationConnectionStatusAll={getConnectedStatusAll(organizations)}
                            componentButtons={renderButtons(false)}
                        />
                    )}
                </>
            );
        }

        return <DefaultStatusContent componentButtons={renderButtons(false)} />;
    };

    return (
        <Styled.ContentContainerCaption>
            <Styled.Content>
                <Styled.LittleTitle>{littleTitle}</Styled.LittleTitle>
                {renderContent()}
            </Styled.Content>
            <ImageStatusBlock
                organizationConnectionStatus={organizationConnectionStatus}
                isAuth={isAuth}
                userPartyId={userPartyId}
                workingPartyId={workingPartyId}
                contentIsLoading={contentIsLoading}
            />
        </Styled.ContentContainerCaption>
    );
};
