import styled, { css } from 'styled-components';
import { rem } from '~/src/helpers/rem-converter.helper';
import { mediaQuery } from '~/src/styles/mixins';
import { MAX_1023, MAX_1279, MAX_767 } from '~/src/styles/tokens/break-points';

export const TitleRect = styled.rect`
    width: ${rem(500)};
    height: ${rem(42)};

    ${mediaQuery(
        { maxWidth: MAX_1279 },
        css`
            width: ${rem(450)};
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_1023 },
        css`
            width: 98%;
        `,
    )};

    ${mediaQuery(
        { maxWidth: MAX_767 },
        css`
            width: 100%;
        `,
    )};
`;
